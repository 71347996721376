import React from 'react';
import PageHeader from '../PageComponents/PageHeader';
import {Link} from 'gatsby';
import Helmet from 'react-helmet';
let gravURL = process.env.REACT_APP_GRAV_URL;
export default class RecipeCollectionsPage extends React.Component {
	componentDidMount(){
		window.scrollTo(0,0)
	}
	render() {
		let collections = this.props.pageContext.collections.map((item, key)=>
			<div className="sm:w-6/12 md:w-4/12 lg:w-4/12 px-4 mb-4" key={key}>
				<div className="mealTileWrapper">
					<Link to={item.route} className="block">
						<img src={gravURL + item.image} alt={item.title} style={{width: '100%', height: 'auto', marginBottom: '10px'}}/>
					</Link>
					<h4 style={{color: '#4d5971', textAlign: 'center'}} className="font-medium text-2xl">{item.title}</h4>
				</div>
			</div>
		)
		return (
			<main>
				<Helmet>
					<title>Recipe Collections | New Image Keto | Alpha Lipid™ SDII Programme</title>	
		            <meta name="description" content="Explore our curated keto recipe collections.  Our recipe collections are curated by a nutritionist to complement our New Image™ Keto Plan." />
		            <meta name="keywords" content="Keto, Ketogenic Diet, Recipes, Recipe Collections, Nutritionist Curated Recipes"/>
		            <meta property="og:image:width" content="1200" />
		            <meta property="og:image:height" content="630" />
		           	<meta property="og:url" content="https://newimageketo.com/recipes/recipe-collections" />
		            <meta property="og:title" content="Recipe Collections | New Image Keto | Alpha Lipid™ SDII Programme"/>
		            <meta name="og:description" content="Explore our curated keto recipe collections.  Our recipe collections are curated by a nutritionist to complement our New Image™ Keto Plan." />
		            <meta name="twitter:description" content="Explore our curated keto recipe collections.  Our recipe collections are curated by a nutritionist to complement our New Image™ Keto Plan." />
		        </Helmet>
				<PageHeader mainHeading="Recipe Collections" subHeading="Explore Our Curated Recipe Collections"/>
				<section>
					<div className="pageGrid">
						<div className="flex flex-wrap">{collections}</div>
					</div>
				</section>
			</main>
		);
	}
}
